//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SizeTable from "@/components/popups/SizeTable/Index.vue";
import SizeGuide from "@/components/popups/SizeGuide/index.vue";
import { mapGetters } from "vuex";
import WaitListSubscribe from "@/components/popups/WaitList/subscribe";
import bellIcon from "@/assets/img/icons/bell-icon.svg";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import SizeElement
    from "~/components/pages/productCard/ProductContent/Components/ProductSizes/Components/SizeElement.vue";

export default {
    name: "ProductSizes",
    components: {SizeElement},
    data: function() {
        return {
            bellIcon: bellIcon,
            subscribeUnavailableSizesId: "waitlist_form",
        }
    },
    props: {
        product: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        ...mapGetters({
            chosenSize: 'productView/getChosenSize',
            sizeGuideData: 'productView/getSizeGuideData',
        }),
        unavailableSizes() {
            return this.product?.sizes?.filter(size => !size.isAvailable);
        },
        availableSizes() {
            return this.product?.sizes?.filter(size => size.hasOwnProperty('isAvailable') && size.isAvailable);
        },
        sizeAlarm() {
            return this.chosenSize.alarm;
        },
        showAlarm() {
            return this.sizeAlarm;
        },
        isButtonBuy() {
            return this.showAlarm && this.chosenSize.buy;
        },
        productHasSizes() {
            return Boolean(this?.product?.sizes?.length);
        },
        showAnimation() {
            return this.sizeAlarm && this.isButtonBuy;
        },
        maxLabelLength() {
            let largest = "";
            for (let i = 0; i < this.product.sizes.length; i++) {
                if (this.product.sizes[i]['title'].length > largest.length) {
                    largest = this.product.sizes[i]['title'];
                }
            }
            return largest.length;
        },
        gridClassName() {
            if (this.maxLabelLength > 18) {
                return 'col-1';
            } else if (this.maxLabelLength > 12) {
                return 'col-2';
            } else if (this.maxLabelLength > 7) {
                return 'col-3';
            } else {
                return 'col-4';
            }
        },
    },
    methods: {
        scrollToHint() {
            if (process.client && window.innerWidth < 1024) {
                let currentScrollPosition = document.documentElement.scrollTop;
                let coordinate = this.$refs.sizes?.getBoundingClientRect();
                if (this.$refs.sizes && coordinate.bottom) {
                    let newScrollPosition = currentScrollPosition + coordinate.bottom - window.innerHeight + 72;
                    if (newScrollPosition > currentScrollPosition){
                        document.documentElement.scrollTo({
                            top: newScrollPosition,
                            behavior: 'smooth'
                        })
                    }
                }
            }
        },
        openSizeGuide() {
            this.$popups.open({
                component: SizeGuide,
                props: {
                    sizeChart: this.product.sizeChart,
                    sizeData: this.sizeGuideData,
                }
            });
        },
        choseSize($event, size) {
            if ($event.target?.classList.contains("disabled")) {
                return;
            }
            if (size.hasOwnProperty("isAvailable") && size.isAvailable === false) {
                this.renderSubscribeSizePopup($event, size);
                return;
            }
            if ((size?.balance ?? 0) <= 5) {
                size.alarm = this.$t('productCard.onWarehouse') + ': ' + size.balance;
            } else {
                size.alarm = '';
            }
            this.$store.commit('productView/SET_CHOSEN_SIZE', size);
            setTimeout(()=>{
                this.scrollToHint();
            })
        },
        renderSubscribeSizePopup($event, size) {
            if ($event.target) {
                $event.target.classList.add("disabled");
            }
            this.$store.dispatch(`forms/loadFormByIdWithData`, {
                formId: this.subscribeUnavailableSizesId
            }).then(() => {
                this.$emit("formLoaded");
                $event.target.classList.remove("disabled");
                this.$popups.open({
                    component: WaitListSubscribe,
                    props: {
                        unavailableSizes: this.unavailableSizes,
                        selectedPropSize: size,
                        formId: this.subscribeUnavailableSizesId
                    }
                });
            });
        }
    },

    mounted() {
        this.$store.commit('productView/SET_CHOSEN_SIZE', {});
        if (this.availableSizes && this.availableSizes.length === 1) {
            this.choseSize(false,this.availableSizes[0]);
        }
    }
}
